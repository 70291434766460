import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class AccesibilityPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title">
                  <h1 className="h3-font mb-3 text-center">Our Commitment to Accessibility</h1>
                  <p>Virtual Capital of America is committed to making our website's content
                    accessible and user friendly to everyone.</p>
                  <br />
                  <p>If you are having difficulty viewing or navigating the content on this website,
                    or notice any content, feature, or functionality that you believe is not fully
                    accessible to people with disabilities, please call our Customer Service team
                    at 00-57-3058126314, Monday to Friday, 9am-8pm GMT-5., or email us at support@virtualcapitalofamerica.com.
                    Please provide a description of the specific feature you feel is not fully accessible
                      or a suggestion for improvement.</p>
                  <br />
                  <p>We take your feedback seriously and will consider it as we evaluate ways to accommodate
                    all of our customers and our overall accessibility policies.</p>
                  <br />
                  <p>Additionally, while we do not control such vendors, we strongly encourage vendors of
                    third-party digital content to provide content that is accessible and user friendly.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default AccesibilityPage;
