import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class WhyusPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title">
                  <h1 className="h3-font mb-3 text-center">Why VCA?</h1>
                  <p className="">Virtual Capital of America is not only a new fintech or a new solution for
                  modern banking, we are developing an alternative economy for break paradigms and old thinking
                  because this is a new era, an era interconnected everyone in the world and our aim is build
                  an ecosystem to be more easy, equitable and neutral. </p>
                  <br />
                  <p>We want democratize the economy and
                  knowledge because we want the people be more thrive in their lives.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default WhyusPage;
