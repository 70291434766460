import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class SecurityPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title">
                  <h1 className="h3-font mb-3 text-center">Security</h1>
                  <p className="">We have a large set of infraestructure in the cloud, but this not means it is insecure
                  because all the information is encrypted and distributed to cold wallets or offline devices with
                  certain periodicity to secure every bit in our network.</p>
                  <br />
                  <p className="">
                    This technology is called <strong>VCA Keyvault</strong> and we are on beta right now, testing out and
                    hacking it to improve the technology and patent it.
                  </p>
                  <br />
                  <p className="">Also we have strong information security to enforce international banking standars
                  to prevent money laundry and terrorism.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default SecurityPage;
