const React = require('react');

class Footer extends React.Component {
  render () {
    const date = new Date()
    return (
      <footer className="footer position-relative bg-color-midnight main-footer">
          <div className="container">
            <div className="row">

              {/* Slogan and links */}
              <div className="col-12 d-flex flex-column justify-content-center mb-3 text-center">
                <img className="d-block mb-2 mx-auto" src="/img/logo.svg" alt="logo inverse" width="75" />
                <p className="w-50 mx-auto">{this.props?.locale?.FOOTER_SLOGAN_LABEL}</p>
                <ul className="w-100 list-unstyled d-flex justify-content-center">
                  <li><a className="border btn btn-rounded mx-1" href="https://twitter.com/vca_global"><span className="icon-twitter"></span></a></li>
                  <li><a className="border btn btn-rounded mx-1" href="https://www.facebook.com/virtualcapitalofamerica/"><span className="icon-facebook"></span></a></li>
                  <li><a className="border btn btn-rounded mx-1" href="https://www.instagram.com/virtualcapitalofamerica/"><span className="icon-instagram"></span></a></li>
                </ul>

                <ul className="w-100 list-unstyled d-flex justify-content-center">
                  <li><a className="mx-1" href="#"><img src="/img/media/button-googleplay-gray.svg" alt="Google play link" /></a></li>
                  <li><a className="mx-1" href="#"><img src="/img/media/button-appstore-gray.svg" alt="App store link" /></a></li>
                </ul>
              </div>

              <div className="col-6 col-sm-4 col-lg-3 mb-20 mb-sm-0">
                <div className="footer-widget">
                  <h6 className="mb-20 text-gold text-uppercase">Company</h6>
                  <ul>
                    <li><a translate="true" href="/about">About Us</a></li>
                    <li><a translate="true" href="/careers">Careers</a></li>
                    <li><a translate="true" href="https://medium.com/virtual-capital-of-america" target="_blank">Blog</a></li>
                    <li><a translate="true" href="/ambassadors">Ambassadors</a></li>
                    <li className="new-feature-container"><a translate="true" href="/roadmap">Roadmap <span className="new-feature">New</span></a></li>
                    <li><a translate="true" href="/contact">Help</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-6 col-sm-4 col-lg-3 mb-20 mb-sm-0">
                <div className="footer-widget">
                  <h6 className="mb-20 text-gold text-uppercase">Resources</h6>
                  <ul>
                    <li><a translate="true" href="/why-vca">Why VCA?</a></li>
                    <li><a translate="true" href="/partnership">Partnerships</a></li>
                    <li><a translate="true" href="/transparency">Transparency</a></li>
                    <li><a translate="true" href="/faq">FAQ</a></li>
                    <li><a translate="true" href="/security">Security at VCA</a></li>
                    <li><a translate="true" href="/accessibility">Accessibility</a></li>
                  </ul>
                </div>
              </div>

              {/* Learn */}
            <div className="col-12 col-md-3">
              <h6 className="mb-20 text-gold text-uppercase">{this.props?.locale?.FOOTER_LEARNTITLE_LABEL}</h6>
              <div className="menu-learn-container footer-widget">
                <ul className="list-unstyled menu">
                  <li><a translate="true" href="/blog">{this.props?.locale?.FOOTER_BLOG_LABEL}</a></li>
                  <li><a translate="true" href="/events">{this.props?.locale?.FOOTER_EVENTS_LABEL}</a></li>
                  <li><a translate="true" href="/news">{this.props?.locale?.FOOTER_NEWS_LABEL}</a></li>
                  <li><a translate="true" href="/help">{this.props?.locale?.FOOTER_HELPCENTER_LABEL}</a></li>
                  <li><a translate="true" href="/faq">{this.props?.locale?.FOOTER_FAQ_LABEL}</a></li>
                </ul>
              </div>
            </div>

            {/* Raise Capital */}
            <div className="col-12 col-md-3">
              <h6 className="mb-20 text-gold text-uppercase">{this.props?.locale?.FOOTER_CAPITALTITLE_LABEL}</h6>
              <div className="menu-raise-capital-container footer-widget">
                <ul className="list-unstyled menu">
                  <li><a translate="true" href="/raise-capital">{this.props?.locale?.FOOTER_CAPITAL_LABEL}</a></li>
                  <li><a translate="true" href="/investor-management-services">{this.props?.locale?.FOOTER_MANAGEMENTSERVICE_LABEL}</a></li>
                  <li><a translate="true" href="/connect">{this.props?.locale?.FOOTER_CONNECT_LABEL}</a></li>
                  <li><a translate="true" href="/raise-capital/process">{this.props?.locale?.FOOTER_GETONMARKET_LABEL}</a></li>
                  <li><a translate="true" href="/raise-capital/results">{this.props?.locale?.FOOTER_RESULTS_LABEL}</a></li>
                  <li><a translate="true" href="/request-info">{this.props?.locale?.FOOTER_REQUESTINFO_LABEL}</a></li>
                </ul>
              </div>
            </div>

            {/* Partners */}
            <div className="col-12">
              <h6 className="mb-20 text-gold text-uppercase">{this.props?.locale?.FOOTER_PARTNERSTITLE_LABEL}</h6>
              <div className="menu-about-container">
                <ul className="d-flex justify-content-center list-unstyled menu row">
                  <li className="col-12 col-md-2"><img className="d-block mx-auto" src="/img/partners/dremind.png" alt="dremind logo" width="100" /></li>
                  <li className="col-12 col-md-2"><img className="d-block mx-auto" src="/img/partners/vca.png" alt="virtual capital of america logo" width="100" /></li>
                  <li className="col-12 col-md-2"><img className="d-block mx-auto" src="/img/partners/microsoft-azure.svg" alt="microsoft azure logo" width="100" /></li>
                </ul>
              </div>
            </div>

            </div>

            {/* Legal */}
            <div id="subfooter" className="row border-top pt-3">
              <div className="col-12 d-flex justify-content-between">
                <p className="">
                  {this.props?.locale?.FOOTER_COPYRIGHT_APENDIXA} {date.getFullYear()} <a translate="true" href="https://www.virtualcapitalofamerica.com/" target="_blank">
                    {this.props?.locale?.FOOTER_COPYRIGHT_APENDIX_B_LINK} </a>. {this.props?.locale?.FOOTER_COPYRIGHT_APENDIX_C}</p>
                <div>
                  <a className="mx-2" translate="true" href="/legal/terms">{this.props?.locale?.FOOTER_TERMS_LABEL}</a>
                  <a className="mx-2" translate="true" href="/legal/posting-policy">{this.props?.locale?.FOOTER_POSTINGPOLICY_LABEL}</a>
                  <a className="mx-2" translate="true" href="/legal/privacy">{this.props?.locale?.FOOTER_PRIVACYPOLICY_LABEL}</a>
                </div>
              </div>
              <div className="col-12">
                <p>{this.props?.locale?.FOOTER_LEGALTERMSP1_LABEL}</p>
                <p>{this.props?.locale?.FOOTER_LEGALTERMSP2_LABEL}</p>
                <p>{this.props?.locale?.FOOTER_LEGALTERMSP3_LABEL}</p>
              </div>
            </div>

          </div>
        {/* <div className="bg-color-grey py-4">
          <div className="container">
            <div className="row">
              <div className="col-12 d-md-flex justify-content-between align-items-center">
                <nav className="nav">
                  <ul className="d-flex mb-md-0">
                    <li><a translate="true" href="https://status.virtualcapitalofamerica.com/">Status</a></li>
                    <li><a translate="true" href="/terms">Privacy & Terms</a></li>
                    <li><a translate="true" href="/contact">Contact Us</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

        </div> */}

      </footer>

    );
  }
}

export default Footer;
