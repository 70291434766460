import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class ContactPage extends React.Component {
  render () {
    let bindings = {
      attr: {
        whatsappHref: {'v-bind:href': 'getWhatsappLink()'}
      },
      events: {},
      lists: {},
      style: {},
      visibility: {}
    }

    return (
      <>

        <section className="space-bottom hero hero--dark hidden">
          <div className="background-holder">
            <img src="/img/contact.jpg" alt="background-image" className="background-image-holder" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto">
                <div className="position-relative text-center">
                  <div className="hero-content hero-content--center">
                    <h1 className="hero__title">Contact us</h1>
                    <p className="lead hero__description">We'd love to talk about how we can help you.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="py-5 bg-color-grey">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 text-center mb-40 mb-lg-0">
                <span className="mb-20 icon-rounded position-relative">
                  <i className="icon icon-pin-3"></i>
                </span>
                <h2 className="h6-font">Address</h2>
                <p>Av. 55 # 152-40 of. 8-504, Bogotá Colombia</p>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-center  mb-40 mb-lg-0">
                <span className="mb-20 icon-rounded position-relative">
                  <i className="icon icon-email"></i>
                </span>
                <h2 className="h6-font">Email</h2>
                <a href="mailto:support@virtualcapitalofamerica.com" className="btn-text-hover">support@virtualcapitalofamerica.com</a>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-center  mb-40 mb-lg-0">
                <span className="mb-20 icon-rounded position-relative">
                  <i className="icon icon-phone-2"></i>
                </span>
                <h2 className="h6-font">Phone Number</h2>
                <a href="tell:+573058126314" className="btn-text-hover">+(57) 305 8126314</a>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 text-center">
                <span className="mb-20 icon-rounded position-relative">
                  <i className="icon fab fa-whatsapp"></i>
                </span>
                <h2 className="h6-font">Whatsapp</h2>
                <a {...bindings.attr.whatsappHref} target="_blank" className="btn-text-hover">Chat now</a>
              </div>
            </div>
          </div>
        </div>
        <div className="space border-bottom-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 mx-auto">
                <div className="section-title text-center mb-80">
                  <h3 className="mb-3">We'd love to hear from you</h3>
                  <p className="lead">Send us a message and we will get back to you within 24 hours </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <form className="form form--sm">
                  <div className="form-row mb-20">
                    <div className="form-group col-sm-12 col-md-6">
                      <label className="label-control mb-10" for="name">Your Name*</label>
                      <input type="text" className="form-control" id="name" placeholder="i.e. John Doe" required />
                    </div>
                    <div className="form-group col-sm-12 col-md-6">
                      <label className="label-control mb-10" for="phone">Your Phone Number</label>
                      <input type="text" className="form-control" id="phone" placeholder="+(123)123 456" />
                    </div>
                  </div>
                  <div className="form-row mb-20">
                    <div className="form-group col-sm-12 col-md-6">
                      <label className="label-control mb-10" for="email">Email address*</label>
                      <input type="email" className="form-control" id="email" placeholder="i.e. youremail@example.com" required />
                    </div>
                    <div className="form-group col-sm-12 col-md-6">
                      <label className="label-control mb-10" for="subject">Subject*</label>
                      <input type="text" className="form-control" id="subject" placeholder="i.e. Question about..." required />
                    </div>
                  </div>
                  <div className="form-row mb-20">
                    <div className="form-group col-sm-12">
                      <label className="label-control mb-10" for="message">Your Message</label>
                      <textarea className="form-control" id="message" rows="4" placeholder="i.e. I have a question about..." required></textarea>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-sm-12 text-center">
                      <button type="submit" className="btn btn--bg-primary btn-splash-hover btn-3d-hover"><span
                        className="btn__text">Submit</span></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="height-emulator d-none d-lg-block"></div>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default ContactPage;
