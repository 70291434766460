import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class FaqPage extends React.Component {
  render () {

    return (
      <>

        <section className="bg-light py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 mx-auto">
                <div className="section-title text-center">
                  <h1 className="h3-font mb-3">Frequently Asked Question</h1>
                  <p className="lead">Millions of people around the world have already made Virtual capital of America the place where their dream happens. </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sticky-block bg-color-grey">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <ul className="sticky-elements smooth-scroll bg-color-primary py-3 px-4 rounded-default box-shadow-2 my-5" id="navbar-nav">
                  <li className="pb-2 mb-10 h5-font color-white border-bottom-light font-w-700">Quick Links</li>
                  <li><a href="#getting" className="transition-default nav-link">Getting Started</a></li>
                  <li><a href="#account" className="transition-default nav-link active">Account</a></li>
                  <li><a href="#pricing" className="transition-default nav-link">Pricing</a></li>
                  <li><a href="#profile" className="transition-default nav-link">Profile</a></li>
                  <li><a href="#legal" className="transition-default nav-link">Legal</a></li>
                </ul>
              </div>

              <div className="col-12 col-md-7 col-lg-7 offset-lg-1">
                <div className="border-bottom-light mb-40 pb-20 my-5" id="getting">
                  <h2 className="h3-font color-primary mb-20">Getting</h2>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> If I have problems or issues with whom can I communicate?</h3>
                    <p>You can contact our email support@virtualcapitalofamerica.com or you can call our corporate mobile
                      number 00-57-3058126314.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> How can I have access to my account?</h3>
                    <p>At this moment you can only access via web because we are developing our mobile app. Meanwhile if
                      you could use our services you cand install the direct access in your phone because our website is
                      mobile-first, this means is developed thinking first on mobile users.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2">How open my account?</h3>
                    <p>Across this website you can find some many buttons with the words "Open account", only you need
                      click on it, register and follow instructions.</p>
                  </article>
                </div>
                <div className="border-bottom-light mb-40 pb-20" id="account">
                  <h2 className="h3-font color-primary mb-20">Account</h2>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> Do I have any restrictions using VCA services if I'm a business?</h3>
                    <p>No, only that the client is responsible for ensuring that the resources managed that are his property, 
                      do not violate the law, and that they have not been proven to be crime, such as money laundering or 
                      terrorist financing.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> I have a business, can I receive payments?</h3>
                    <p>Yes, when you open an account with us you can register your business for accept credit and debit 
                      cards, crypto-actives (Bitcoin, Ethereum, Litecoin and more) and other local payment business.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> Can I synchronize my stock with payments?</h3>
                    <p>We have a dedicated business selling tools and other specialized tools, one of them is a 
                      complete POS system to manage stocks, sales, invoices, providers, users and more. Discover it.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> If I'm younger, can I use VCA?</h3>
                    <p>Our services are now limited to people over 18 years of age at this time.</p>
                  </article>
                </div>

                <div className="border-bottom-light mb-40 pb-20" id="pricing">
                  <h2 className="h3-font color-primary mb-20">Pricing</h2>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2">What pricing does VCA has for using it's services?</h3>
                    <p>Is totally free between VCA users but if your a business or wants outside VCA world you need visit section <a href="https://www.virtualcapitalofamerica.com/terms">terms and conditions</a>, you can find the latest news about our products and services.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> VCA handle hidden costs?</h3>
                    <p>No, our costs are public and you can show it every time you need. Only thing you need to know is we use percentages and not fixed costs.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2">How does the refund policy work?</h3>
                    <p>Some many about our services has the refund policy and this is called a dispute, you could open any you wants and we are very happy to study your case.</p>
                  </article>
                </div>

                <div className="border-bottom-light mb-40 pb-20" id="profile">
                  <h2 className="h3-font color-primary mb-20">Profile</h2>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> How many times will I be charged?</h3>
                    <p>This your decision, you can charge money when you need to your wallet or can schedule when the amount is low.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> My profile is public?</h3>
                    <p>No, the major information about you is encrypted and saved in cold wallets (offline systems), only you can share 
                      your public information like name to your desired contacts.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2">Why VCA needs to much personal information?</h3>
                    <p>To enforce international requirements to prevent money laundry and terrorism whe need to know you, this
                      information is only needed when international organizations require to audit information about our clients.</p>
                  </article>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2">Why VCA need access to camera and other sensors like biometrics?</h3>
                    <p>We want to improve your life and this aim is only possible if we develop some many tools like payments
                      with QR codes or security with your fingerprints, we don't want to add more problems for you.</p>
                  </article>
                </div>

                <div id="legal">
                  <h2 className="h3-font color-primary mb-20">Legal</h2>
                  <article className="mb-30">
                    <h3 className="h6-font mb-2"> In which cities are VCA located?</h3>
                    <p>Currently VCA services operate in the Colombian national territory while is in beta,
                      when we are landing on more cities or countries we are pushing this information in blogs
                      and social media.</p>
                  </article>
                </div>

              </div>
            </div>
          </div>
        </section>
        <div className="stickyBlockEndPoint"></div>

        <section className="bg-color-primary space lastobject">
          <div className="background-holder background-holder--contain">
            <img src="/img/cta-bg.png" alt="pattern" className="background-image-holder" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 text-center mx-auto">
                <h3 className="mb-20">Try VCA, for free!</h3>
                <p className="lead mb-50">Millions of people around the world have already made Virtual capital of America the place where their dream happens.</p>
                <a href="#" className="btn btn--bg-white btn--color-primary btn-3d-hover btn-splash-hover">
                  <span className="btn__text">Open account</span>
                </a>
              </div>
            </div>
          </div>
        </section>

        <div className="height-emulator d-none d-lg-block"></div>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default FaqPage;
