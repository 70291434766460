import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class AboutPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title">
                  <h1 className="h3-font mb-3 text-center">About</h1>
                  <p>Virtual Capital of America was born at the end of 2017, with the aim
                  of uniting the management of traditional currencies (dollars, pesos, euros) and digital
                  currencies or cryptocurrencies, offering incredible opportunities for the financial
                  development of people, businesses, and governments. </p>
                  <br />
                  <p>We focus on creating 100% digital financial solutions, making our users' 
                    lives easier, allowing them to get more out of
                  their assets. Our promise of value is based on agile, safe and reliable products and services.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default AboutPage;
