import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class RoadmapPage extends React.Component {
  render () {

    return (
      <>
        <link rel="stylesheet" href="css/roadmap.css" />
        <section className="space bg-light">
          <div className="container-fluid">
            <div className="row example-centered">
              <div className="col-md-12 example-title">
                <h2>Roadmap</h2>
                <p>From our idea born to nowadays.</p>
              </div>

              <div className="d-flex justify-content-center">
                <div className="col-8 col-xs-12">
                  <ul className="timeline timeline-centered">
                    {/* 2017 Period marker */}
                    <li className="timeline-item period">
                      <div className="timeline-info"></div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h2 className="timeline-title">2017</h2>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>September 12, 2017</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Crypto currency mining</h3>
                        <p>Our team have a new aim, do a hardware and software to mining crypto currencies.
                          At this time the team needed to start crypto currencies study, cryptography, maths
                          and advanced software skills.
                        </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>November 23, 2017</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Architecture design</h3>
                        <p>To do a better "crypto-farm" is needed develop a good architecture, from the bases to
                          more higher endpoint. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>December 15, 2017</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Wallet design</h3>
                        <p>Is important develop a secure wallet to storage all keys because the provided by
                          markets and the third offers is not enough to the needs in the project. But in this
                          point the project is to big to handle a simple wallet. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>December 25, 2017</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA born</h3>
                        <p>We have an awesome idea and wonderfull services backed with IA, DevOps automation,
                          cryptography and architecture so robust only for us. We decided, a new ecosystem
                          born. </p>
                      </div>
                    </li>
                    {/* 2018 Period marker */}
                    <li className="timeline-item period">
                      <div className="timeline-info"></div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h2 className="timeline-title">2018</h2>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>January 12, 2018</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA design</h3>
                        <p>Our team worked so hard design the better services and products for the new ecosystem,
                          at this point is developed the more basic software engineer documents to synchronize 
                          everyone and planning to do a planning.</p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>February 16, 2018</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA MVP</h3>
                        <p>The MVP was start the development to validate the idea with beta testers. The game
                          was started here 😉
                        </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>March 02, 2018</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA self-funding</h3>
                        <p>To start this new project all team members worked so hard to raise funding with 
                          freelance jobs and working at same time in the MVP. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>December 13, 2018</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA traction</h3>
                        <p>To be a real banking company we need to start the race of traction,
                          this hard step is so important to know if the idea is valid or simply is an idea. 
                          At this point we decided no invest all our capital in marketing campaings, we decided
                          helping our future network reducing the pain points in our base customers, the business;
                          our premise, if we gain the heart of business is more easy gain end customers. Via real
                          tools we developed some many tools to improve the life about our partners, our friends.</p>
                      </div>
                    </li>
                    {/* 2019 Period marker */}
                    <li className="timeline-item period">
                      <div className="timeline-info"></div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h2 className="timeline-title">2019</h2>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>January 01, 2019</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA Beta</h3>
                        <p>VCA have the roots to work, is time to test it with real users and customers,
                          every day hacking our systems to improve the security and the trust on us. </p>
                      </div>
                    </li>
                    {/* Future Period marker */}
                    <li className="timeline-item period">
                      <div className="timeline-info"></div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h2 className="timeline-title">Future</h2>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>April 18, 2019</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Outbound marketing</h3>
                        <p>We will start selling our tools with a price lower than your Netflix subscription,
                          reducing the pain point about business and reaching all business in the cities of Colombia
                          and Argentina. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>May, 2019</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Inbound marketing</h3>
                        <p>After we have a solid business network we hunting our final customers providing a big
                          network to use our ecosystem closing the ring. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>June, 2019</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Expansion</h3>
                        <p>We want to gain major Latin America cities using VCA, but we not focused only on big
                          cities because we want to improve the digital transformation in every business in American 
                          continent. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>July, 2019</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Investments & exchange</h3>
                        <p>Doing a complete set of services we will develop the two restant pillars in our
                          ecosystem, a way to invest the money and a exchange section to more experienced users. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>September, 2019</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">Micro credits</h3>
                        <p>We understand every person in a moment of the life need a quick help, current solutions
                          are to expensive and abusive. For this reason we want to develop our department focused on
                          micro credits. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>October, 2019</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA credit card</h3>
                        <p>We will develop our own credit card to do withdrawals in any ATM in the world and
                          developing our premium card for more technological users. This will be a new era of
                          cards. Our research team are working on it since February 2019. Check our blog for
                          news 😉 </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>June, 2020</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA insurances</h3>
                        <p>We want to improve the life of our customers in all aspects, and a program of
                          insurances is very important for us because we are pushing their lifes more security. </p>
                      </div>
                    </li>
                    <li className="timeline-item">
                      <div className="timeline-info">
                        <span>June, 2020</span>
                      </div>
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <h3 className="timeline-title">VCA headquarters</h3>
                        <p>In 2020 we want to move to Canada as headquarters providing a new and fresh technological
                          hub.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default RoadmapPage;
