import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class TransparencyPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title">
                  <h1 className="h3-font mb-3 text-center">Transparency</h1>
                  <p className="">Having AI and a good infrastructure doesn’t mean that we’ll blindly 
                  and automatically pay every claim we get. VCA is a not regulated business, because we are not
                  a real bank, we are a technological platform to provide financial services to your digital assets
                  and a way to share or retrieve this, in other words a wallet combined with a payment gateway.</p>
                  <p className="">
                  <br />
                  So, setting expectations is very important, and is something we’ll be working on improving 
                  constantly. Our policy forms are still based on the regulatory approved format, and they’re lame, we know 😉
                  </p>
                  <br />
                  <p className="">Our dream is to be a real bank in the not too distant future to provide you 
                  more interesting services, like loans or insurances. We only made this dream with your help.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default TransparencyPage;
