import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class BusinessPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      defaultCountryCode: '+57'
    }
  }

  render () {
    return (
      <>

        {/* Hero */}
        <section className="hero pb-5 bg-color-midnight">
          <div className="background-holder background-image-pattern-light"></div>

          <div className="container">
            <div className="row align-items-lg-center flex-lg-row-reverse flex-xl-row">
              {/* this image only visible on mobile */}
              <div className="col-12 col-lg-6 d-xl-none">
                <div className="rounded-circle hero__block5-mobile background-holder--cover background-holder--center d-xl-none mx-auto mb-30 background-biz-image-hero"></div>
              </div>

              <div className="col-12 col-lg-6 z-BusinessPage1">
                <div className="d-lg-flex align-items-lg-center position-relative">
                  <div className="hero-content">
                    <h5 className="text-secondary">{this.props?.locale?.MAIN_SUBTITLE_LABEL}</h5>
                    <h1 className="hero__title font-w-900 mb-40 text-gold">{this.props?.locale?.MAIN_TITLE_LABEL}</h1>
                    <p className="lead hero__description">{this.props?.locale?.MAIN_DESCRIPTION_LABEL}</p>
                    {/* Call to action form */}
                    <form className="d-flex">
                      <div className="col-2 p-0 border-right">
                        <input type="text" className="form-control border-bottom-radius-0 border-top-radius-0" placeholder={this.props?.locale?.MAIN_COUNTRYCODE_PLACEHOLDER} defaultValue={this.state.defaultCountryCode} />
                      </div>
                      <div className="col p-0">
                        <div className="input-group">
                          <input type="phone" className="form-control border-bottom-radius-0 border-top-radius-0" placeholder={this.props?.locale?.MAIN_PHONE_PLACEHOLDER} required />
                          <div className="invalid-feedback">{this.props?.locale?.MAIN_ERRORPHONE_LABEL}</div>
                          <div className="input-group-append">
                            <button className="btn btn-gold btn-splash-hover" type="button">{this.props?.locale?.MAIN_GETSTARTED_BUTTON}</button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <section className="button-group align-items-center justify-content-sm-center justify-content-lg-start mt-30">
                      <div className="mr-2 my-2 store-logo-container">
                        <img src="/img/media/app-store-white.svg" alt="app store logo" />
                      </div>
                      <div className="mx-2 my-2 store-logo-container">
                        <img src="/img/media/google-play-white.svg" alt="google play logo" />
                      </div>
                      <a className="btn btn-gold btn--sm lightbox position-relative btn-3d-hover btn-splash-hover mx-2"
                        data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=b1lPuCBYtvc">
                        <span className="pos-vertical-center media-player-icon media-player-icon--sm media-player-icon--fill-gold-inverse">
                          <i className="fa fa-play"></i>
                        </span>
                        <span className="ml-30">{this.props?.locale?.MAIN_WATCHVIDEO_BUTTON}</span>
                      </a>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-9 col-xl-6 pos-abs-top-right pr-0 d-none d-xl-block h-100">
            <div className="hero__block5 background-holder--cover background-holder--center ml-auto h-100 background-biz-image-hero"></div>
            {/* this svg background only visible on large device */}
            <svg height="0" width="0">
              <defs>
                <clipPath id="hero__block5">
                  <path d="M0,0c0,0,18.2,428.9,283.2,549.5S655.1,598.4,800,680V0H0z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </section>
        {/* End Hero */}

        {/* Covid Help */}
        <section className="bg-color-darkblue py-5">
          <div className="container">
            <div className="row d-flex">
              <div className="d-flex mx-4">
                <img src="/img/covid/sign.png" alt="covid help image" className="d-block my-auto" />
              </div>
              <div className="d-flex flex-column justify-content-center mx-3">
                <h3 className="font-w-300 text-white">{this.props?.locale?.COVID_TITLE_LABEL}</h3>
              </div>
              <div className="col-12 col-md-5 d-flex flex-column justify-content-center mx-0 my-5 mx-md-3 my-md-0">
                <p className="text-center text-md-left text-white-50">{this.props?.locale?.COVID_DESCRIPTION_LABEL}</p>
              </div>
              <div className="col-12 col-md-2 d-flex flex-column justify-content-center">
                <a translate="true" href="#" className="btn btn--sm btn-outline-info my-3">{this.props?.locale?.COVID_READMORE_BUTTON}</a>
              </div>
            </div>
          </div>
        </section>
        {/* End Covid Help */}

        {/* Microcredits */}
        <section className="bg-color-grey">
          {/* Desktop view */}
          <div className="d-none d-md-flex justify-content-between">
            <div className="col-6 p-0">
              <img src="/img/home/microcredit.png" alt="debit card vca" className="d-block mr-auto" />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <div className="mx-5">
                <h3 className="font-size-51">{this.props?.locale?.MICROCREDIT_TITLE_LABEL}</h3>
                <h5 className="font-size-41 font-w-300">{this.props?.locale?.MICROCREDIT_DESCRIPTION_LABEL}</h5>
                <a translate="true" href="#" className="btn btn-border-hover btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.MICROCREDIT_READMORE_BUTTON}</a>
              </div>
            </div>
          </div>
          {/* Mobile view */}
          <div className="d-md-none d-lg-none d-xl-none pt-5">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center p-0">
              <div className="text-center">
                <h3>{this.props?.locale?.MICROCREDIT_TITLE_LABEL}</h3>
                <h5 className="font-w-300">{this.props?.locale?.MICROCREDIT_DESCRIPTION_LABEL}</h5>
                <a translate="true" href="#" className="btn btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.MICROCREDIT_READMORE_BUTTON}</a>
              </div>
              <div className="col-12 p-0">
                <img src="/img/home/microcredit-mobile.png" alt="debit card vca mobile" className="d-block ml-auto" />
              </div>
            </div>
          </div>
        </section>
        {/* End Microcredits */}

        {/* Debit card */}
        <section className="bg-light">
          {/* Desktop view */}
          <div className="d-none d-md-flex justify-content-between">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <div className="mx-5">
                <h3 className="font-size-51">{this.props?.locale?.DEBITCARD_TITLE_LABEL}</h3>
                <h5 className="font-size-41 font-w-300">{this.props?.locale?.DEBITCARD_DESCRIPTION_LABEL}</h5>
                <a translate="true" href="#" className="btn btn-border-hover btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.DEBITCARD_READMORE_BUTTON}</a>
              </div>
            </div>
            <div className="col-6 p-0">
              <img src="/img/home/team.jpg" alt="team vca" className="d-block ml-auto" />
            </div>
          </div>
          {/* Mobile view */}
          <div className="d-md-none d-lg-none d-xl-none pt-5">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <div className="text-center">
                <h3>{this.props?.locale?.DEBITCARD_TITLE_LABEL}</h3>
                <h5 className="font-w-300">{this.props?.locale?.DEBITCARD_DESCRIPTION_LABEL}</h5>
                <a translate="true" href="#" className="btn btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.DEBITCARD_READMORE_BUTTON}</a>
              </div>
              <div className="col-12 p-0">
                <img src="/img/home/debitcard-mobile.png" alt="debit card vca mobile" className="d-block ml-auto" />
              </div>
            </div>
          </div>
        </section>
        {/* End Debit card */}

        {/* Key features */}
        <section className="bg-light pt-5">
          <div className="container">
            <div className="d-flex justify-content-center row">
              <div className="col-12 col-md-8">
                <h3 className="font-size-51 text-center my-4 pb-5">{this.props?.locale?.FEATURES_TITLE_LABEL}</h3>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between gallery">
            {/* Column 1 */}
            <div className="col-6 col-md-2 vertical-gallery">
              <div className="d-flex flex-column">
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/01.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-warning">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE01_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/02.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-primary">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE02_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/17.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-warning">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE03_LABEL}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Column 2 */}
            <div className="col-6 col-md-2 vertical-gallery">
              <div className="d-flex flex-column">
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/03.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-darkblue">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE04_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/04.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-white">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE05_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/06.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-darkblue">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE06_LABEL}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Column 3 */}
            <div className="col-6 col-md-2 vertical-gallery">
              <div className="d-flex flex-column">
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/05.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-primary">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE07_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/07.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-warning">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE08_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/16.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-primary">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE09_LABEL}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Column 4 */}
            <div className="col-6 col-md-2 vertical-gallery">
              <div className="d-flex flex-column">
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/09.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-white">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE10_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/10.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-primary">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE11_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/11.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-white">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE12_LABEL}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Column 5 */}
            <div className="col-6 col-md-2 vertical-gallery">
              <div className="d-flex flex-column">
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/12.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-darkblue">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE13_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/08.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-white">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE14_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/13.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-darkblue">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE15_LABEL}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Column 6 */}
            <div className="col-6 col-md-2 vertical-gallery">
              <div className="d-flex flex-column">
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/14.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-warning">
                    <p className="font-weight-light text-center">{this.props?.locale?.FEATURES_FEATURE16_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/17.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-primary">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE17_LABEL}</p>
                  </div>
                </div>
                <div className="border-bottom-radius-md border-top-radius-md card my-2 shadow gallery-item btn-3d-hover">
                  <img src="/img/features/15.jpg" className="border-top-radius-md card-img-top" alt="..." />
                  <div className="border-bottom-radius-md px-3 card-body bg-color-primary">
                    <p className="font-weight-light text-center text-white">{this.props?.locale?.FEATURES_FEATURE18_LABEL}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Key features */}

        {/* Subscriptions */}
        <section className="bg-color-grey">
          {/* Desktop view */}
          <div className="d-none d-md-flex justify-content-between">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <div className="mx-5">
                <h3 className="font-size-51">{this.props?.locale?.SUBSCRIPTIONS_TITLE_LABEL}</h3>
                <h5 className="font-size-41 font-w-300">{this.props?.locale?.SUBSCRIPTIONS_DESCRIPTION_LABEL}</h5>
                <a translate="true" href="#" className="btn btn-border-hover btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.SUBSCRIPTIONS_READMORE_BUTTON}</a>
              </div>
            </div>
            <div className="col-6 p-0">
              <img src="/img/home/subscriptions.png" alt="debit card vca" className="d-block ml-auto" />
            </div>
          </div>
          {/* Mobile view */}
          <div className="d-md-none d-lg-none d-xl-none pt-5">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <div className="text-center">
                <h3>{this.props?.locale?.SUBSCRIPTIONS_TITLE_LABEL}</h3>
                <h5 className="font-w-300">{this.props?.locale?.SUBSCRIPTIONS_DESCRIPTION_LABEL}</h5>
                <a translate="true" href="#" className="btn btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.SUBSCRIPTIONS_READMORE_BUTTON}</a>
              </div>
              <div className="col-12 p-0">
                <img src="/img/home/subscriptions-mobile.png" alt="debit card vca mobile" className="d-block ml-auto" />
              </div>
            </div>
          </div>
        </section>
        {/* End Subscriptions */}

        {/* Discounts */}
        <section className="bg-light pt-5">
          {/* Desktop view */}
          <div className="d-none d-md-flex justify-content-between">
            <div className="col-6 p-0">
              <img src="/img/home/discounts.png" alt="debit card vca" className="d-block mr-auto" />
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
              <div className="mx-5">
                <h3 className="font-size-51">{this.props?.locale?.DISCOUNTS_TITLE_LABEL}</h3>
                <h5 className="font-size-41 font-w-300 mb-3">{this.props?.locale?.DISCOUNTS_DESCRIPTION_LABEL}</h5>
                <h5 className="font-size-21 font-w-300 text-muted">{this.props?.locale?.DISCOUNTS_SUBDESCRIPTION_BUTTON}</h5>
                <a translate="true" href="#" className="btn btn-border-hover btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.DISCOUNTS_READMORE_BUTTON}</a>
              </div>
            </div>
          </div>
          {/* Mobile view */}
          <div className="d-md-none d-lg-none d-xl-none pt-5">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center p-0">
              <div className="text-center">
                <h3>{this.props?.locale?.DISCOUNTS_TITLE_LABEL}</h3>
                <h5 className="font-w-300 mb-3 px-3">{this.props?.locale?.DISCOUNTS_DESCRIPTION_LABEL}</h5>
                <h5 className="font-w-300 text-muted px-3">{this.props?.locale?.DISCOUNTS_SUBDESCRIPTION_BUTTON}</h5>
                <a translate="true" href="#" className="btn btn--arrow-after btn--sm pl-0 my-3">{this.props?.locale?.DISCOUNTS_READMORE_BUTTON}</a>
              </div>
              <div className="col-12 p-0">
                <img src="/img/home/discounts-mobile.png" alt="debit card vca mobile" className="d-block ml-auto" />
              </div>
            </div>
          </div>
        </section>
        {/* End Discounts */}

        {/* Start of Steps  */}
        <section className="bg-light space--md">
          <div className="container">
            <div className="bg-white card d-flex flex-row row mobile-bg mx-2 mx-md-0 shadow border-0">

              <div className="col-12 col-md-10 col-lg-5 mb-lg-0 p-md-5">
                <p className="mb-10 text-uppercase font-w-700 mt-4 mt-md-0">{this.props?.locale?.STEPS_SUBTITLE_LABEL}</p>
                <h3 className="mb-20">{this.props?.locale?.STEPS_TITLE_LABEL}</h3>
                <p className="mb-0 mb-md-4">{this.props?.locale?.STEPS_DESCRIPTION_LABEL}</p>

                {/* Desktop Call to action form */}
                <form className="d-none d-md-flex">
                  <div className="col-2 p-0">
                    <input type="text" className="form-control border-right-0" placeholder={this.props?.locale?.STEPS_COUNTRYCODE_PLACEHOLDER} defaultValue={this.state.defaultCountryCode} />
                  </div>
                  <div className="col p-0">
                    <div className="input-group">
                      <input type="phone" className="form-control border-left-0" placeholder={this.props?.locale?.STEPS_PHONE_PLACEHOLDER} required />
                      <div className="invalid-feedback">{this.props?.locale?.STEPS_ERRORPHONE_LABEL}</div>
                      <div className="input-group-append">
                        <button className="btn btn--bg-primary btn-splash-hover" type="button">{this.props?.locale?.STEPS_GETSTARTED_BUTTON}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="col-12 col-md-9 col-lg-6 ml-lg-auto d-flex flex-column justify-content-center mt-4 mt-md-0 steps-background">
                <div className="step-item d-flex justify-content-center justify-content-md-start" data-step="1">
                  <div className="card d-flex flex-row my-2 shadow-sm py-2 px-1 col-10 col-md-6 p-0">
                    <img src="/img/home/step-01.svg" width="52" className="mr-3" alt="step 01" />
                    <div className="d-flex flex-column">
                      <p className="font-weight-bold mb-1">{this.props?.locale?.STEPS_STEP01TITLE_LABEL}</p>
                      <p className="text-muted">{this.props?.locale?.STEPS_STEP01DESCRIPTION_LABEL}</p>
                    </div>
                  </div>
                </div>
                <div className="step-item d-flex justify-content-center justify-content-md-start" data-step="2">
                  <div className="card d-flex flex-row my-2 shadow-sm py-2 px-1 col-10 col-md-6 p-0">
                    <img src="/img/home/step-02.svg" width="52" className="mr-3" alt="step 02" />
                    <div className="d-flex flex-column">
                      <p className="font-weight-bold mb-1">{this.props?.locale?.STEPS_STEP02TITLE_LABEL}</p>
                      <p className="text-muted">{this.props?.locale?.STEPS_STEP02DESCRIPTION_LABEL}</p>
                    </div>
                  </div>
                </div>
                <div className="step-item d-flex justify-content-center justify-content-md-start" data-step="3">
                  <div className="card d-flex flex-row my-2 shadow-sm py-2 px-1 col-10 col-md-6 p-0">
                    <img src="/img/home/step-03.svg" width="52" className="mr-3" alt="step 03" />
                    <div className="d-flex flex-column">
                      <p className="font-weight-bold mb-1">{this.props?.locale?.STEPS_STEP03TITLE_LABEL}</p>
                      <p className="text-muted">{this.props?.locale?.STEPS_STEP03DESCRIPTION_LABEL}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mobile Call to action form */}
              <div className="col-12 d-flex d-md-none d-lg-none d-xl-none my-4">
                <form className="d-flex">
                  <div className="col-2 p-0">
                    <input type="text" className="form-control border-right-0" placeholder={this.props?.locale?.STEPS_COUNTRYCODE_PLACEHOLDER} defaultValue={this.state.defaultCountryCode} />
                  </div>
                  <div className="col p-0">
                    <div className="input-group">
                      <input type="phone" className="form-control border-left-0" placeholder={this.props?.locale?.STEPS_PHONE_PLACEHOLDER} required />
                      <div className="invalid-feedback">{this.props?.locale?.STEPS_ERRORPHONE_LABEL}</div>
                      <div className="input-group-append">
                        <button className="btn btn--bg-primary btn-splash-hover" type="button">{this.props?.locale?.STEPS_GETSTARTED_BUTTON}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </section>
        {/* End of Steps  */}

        {/* Start of Video  */}
        <section className="bg-color-grey space--md">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-5 mb-50 mb-lg-0">
                <p className="mb-10 text-uppercase font-w-700">{this.props?.locale?.VIDEO_SUBTITLE_LABEL}</p>
                <h3 className="mb-20">{this.props?.locale?.VIDEO_TITLE_LABEL}</h3>
                <p className="mb-40">{this.props?.locale?.VIDEO_DESCRIPTION_LABEL}</p>
                <a translate="true" href="/benefits" className="btn btn--transparent p-0 btn--arrow-after btn--color-dark btn-border-hover">
                  <span className="btn__text mr-30">{this.props?.locale?.VIDEO_READMORE_BUTTON}</span>
                </a>
              </div>
              {/* end of col */}
              <div className="col-12 col-md-9 col-lg-6 ml-lg-auto">
                <div className="position-relative">
                  {/* <video className="d-none" src="https://youtu.be/Cn4RF21RIdE"></video> */}
                  <picture><img className="rounded-default" src="/img/video/video.jpg" alt="" /></picture>
                  <a className="lightbox pos-center media-player-icon media-player-icon--md media-player-icon--fill-primary btn-splash-hover"
                    data-autoplay="true" data-vbtype="video" href="https://youtu.be/DMPFGaaSOE0"><i className="fa fa-play"></i>
                  </a>
                  {/* <a className="lightbox pos-center media-player-icon media-player-icon--md media-player-icon--fill-primary btn-splash-hover"
                    data-autoplay="true" data-vbtype="video" data-vbtype="iframe" href="https://s3.envato.com/h264-video-previews/f40b7bf3-1bfc-4976-ad5c-7fc7b61f3bff/23113041.mp4"><i className="icon icon-triangle-right-17"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End of Video  */}

        {/* Plans */}
        <section className="bg-light space--md">
          <div className="container">
            <div className="d-flex justify-content-center row">
              <div className="col-12 col-md-8">
                <h3 className="font-size-51 text-center my-4">{this.props?.locale?.PLANS_TITLE_LABEL}</h3>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-4 text-center">
                <img src="/img/cards/01.png" alt="card 01" />
                <h4>{this.props?.locale?.PLANS_PLAN01TITLE_LABEL}</h4>
                <p className="text-muted">{this.props?.locale?.PLANS_PLAN01DESCRIPTION_LABEL}</p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src="/img/cards/03.png" alt="card 01" />
                <h4>{this.props?.locale?.PLANS_PLAN03TITLE_LABEL}</h4>
                <p className="text-muted">{this.props?.locale?.PLANS_PLAN04DESCRIPTION_LABEL}</p>
              </div>
            </div>
            <div className="col-12 text-center mt-5">
              <a translate="true" href="#" className="btn btn--bg-primary btn--sm btn-3d-hover btn-splash-hover get-started-main">{this.props?.locale?.PLANS_CHECKOUT_BUTTON}</a>
            </div>
          </div>
        </section>
        {/* End Plans */}

        {/* Q&A */}
        <section className="bg-light space--xl bg-questions">
          <div className="container">
            <div className="d-flex justify-content-end row">
              <div className="col-12 col-md-6 bg-color-darkblue p-5">
                <h3 className="font-size-51 my-4 w-75">{this.props?.locale?.KEYQUESTIONS_TITLE_LABEL}</h3>
                <a translate="true" href="/faq" className="btn btn-border-hover btn--arrow-after btn--color-white btn--sm pl-0 my-3">{this.props?.locale?.KEYQUESTIONS_READMORE_BUTTON}</a>
              </div>
            </div>
          </div>
        </section>
        {/* End Q&A */}

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default BusinessPage;
