import React from "react";
import { Outlet } from "react-router-dom";
import HeaderMain from '../components/navigation/_navGeneral'

const LayoutGeneral = () => {
  return (
    <>
      <div className="main">
        <HeaderMain />

        {/* Start Content */}
        <Outlet />
        {/* End Content */}
      </div>
    </>
  );
};

export default LayoutGeneral;