import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.css';

import {
  LandingCovid,
  MaintenanceGeneral,
  AboutPage,
  AccesibilityPage,
  AmbassadorsPage,
  BenefitsPage,
  BusinessPage,
  CareersPage,
  ComingSoonPage,
  ContactPage,
  FaqPage,
  NetworkPage,
  PartnershipPage,
  RoadmapPage,
  SecurityPage,
  ServicesPage,
  TermsPage,
  TransparencyPage,
  WhyusPage
} from './pages/index';
import {
  LayoutMaintenance,
  LayoutGeneral
} from './layouts/index';

import localeENG from './locales/en_US.locale.json';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutGeneral locale={localeENG.dictionary.home.index} />}>
          <Route index element={<LandingCovid locale={{ ...localeENG.dictionary.home.index, ...localeENG.dictionary.shared }} />} />

          <Route path="/services" element={<ServicesPage locale={{ ...localeENG.dictionary.services.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/benefits" element={<BenefitsPage locale={{ ...localeENG.dictionary.benefits.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/about" element={<AboutPage locale={{ ...localeENG.dictionary.about.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/careers" element={<CareersPage locale={{ ...localeENG.dictionary.careers.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/ambassadors" element={<AmbassadorsPage locale={{ ...localeENG.dictionary.ambassadors.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/roadmap" element={<RoadmapPage locale={{ ...localeENG.dictionary.roadmap.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/partnership" element={<PartnershipPage locale={{ ...localeENG.dictionary.partnership.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/transparency" element={<TransparencyPage locale={{ ...localeENG.dictionary.transparency.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/faq" element={<FaqPage locale={{ ...localeENG.dictionary.faq.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/security" element={<SecurityPage locale={{ ...localeENG.dictionary.security.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/accessibility" element={<AccesibilityPage locale={{ ...localeENG.dictionary.accessibility.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/why-vca" element={<WhyusPage locale={{ ...localeENG.dictionary.whyus.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/terms" element={<TermsPage locale={{ ...localeENG.dictionary.terms.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/contact" element={<ContactPage locale={{ ...localeENG.dictionary.contact.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/business" element={<BusinessPage locale={{ ...localeENG.dictionary.business.index, ...localeENG.dictionary.shared }} />} />
          <Route path="/network" element={<NetworkPage locale={{ ...localeENG.dictionary.network.index, ...localeENG.dictionary.shared }} />} />
        </Route>

        <Route path="*" element={<LayoutMaintenance />} >
          <Route path="*" element={<MaintenanceGeneral />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
