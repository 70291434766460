import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class CareersPage extends React.Component {
  render () {

    return (
      <>

        <div className="container main-content">
          <div className="row py-5 my-5">
            <div className="col-12 grid-margin mx-auto d-block">
              <div className="card border-0">
                <div className="card-body">
                  <div className="container pt-2">
                    <h4 className="card-title">{this.props?.locale?.MAIN_TITLE_LABEL}</h4>
                    <p className="card-description">{this.props?.locale?.MAIN_DESCRIPTION_LABEL}</p>
                    <a href="/" translate="true" className="btn btn--bg-primary btn--outline btn--outline-thick btn--sm btn-3d-hover btn-splash-hover get-started-main my-3">{this.props?.locale?.MAIN_GOBACK_BUTTON}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default CareersPage;
