import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class AmbassadorsPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title">
                  <h1 className="h3-font mb-3 text-center">Ambassador program</h1>
                  <p>To become a member of our new ambassador program, simply fill out the form below.</p>
                  <br />
                  <p>If you are accepted into our program, we will generate a 50% in our fees that you can use to
                    purchase other products or generate revenue with withdrawals every month. As part of our trial
                    period, when you have minimum of 20 payments with new users your ambassador code in the platform,
                    you will be eligible to choose and receive a FREE ambassador kit. Your friends
                    and/or following can also get involved by using your ambassador code to help you reach
                    the 20 payments to get your ambassador kit.</p>
                  <br />
                  <p>If we see consistency with our ambassadors, we will give the option to start getting paid
                    per post they do with us (sponsorship posts) or commission based affiliates. </p>
                  <br />
                  <p>Tag us in your social media post for a chance to be featured on our social media; this
                    will grow both your following and our's. Also is a good metric to know your performance as
                    ambassador.</p>
                  <br />
                  <p>We are excited to work with you!.</p>
                  <br />
                  <p>If you have any questions, please feel free to contact us <a href="mailto:support@virtualcapitalofamerica.com"> here</a>!</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default AmbassadorsPage;
