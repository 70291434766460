import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class BenefitsPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title text-center">
                  <h1 className="h3-font mb-3">Benefits for you.</h1>
                  <p className="lead">When you choose VCA, you not only gain access to an unsurpassed
                  network of businesses that accept our brands, you can also experience a wide
                  range of features and benefits.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-main bg-color-grey">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/edge-security.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Edge safety</h2>
                        </a>
                        <div className="mb-4">
                          <p>with Key Vault ©, proprietary and patented technology</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/send-money.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Sending money</h2>
                        </a>
                        <div className="mb-4">
                          <p>anywhere in the world</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/kyc.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Strong KYC polices</h2>
                        </a>
                        <div className="mb-4">
                          <p>to prevent bank fraud and money laundering</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/network.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Auditable technology</h2>
                        </a>
                        <div className="mb-4">
                          <p>Key Vault © allows acting on behalf of the control and legislation bodies</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/open.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Always open</h2>
                        </a>
                        <div className="mb-4">
                          <p>your money always available  for your needs</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/piggy-bank.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Low cost transactions</h2>
                        </a>
                        <div className="mb-4">
                          <p>You are free of any cost of payment and conversions in the VCA network</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/finances.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Manage your finances</h2>
                        </a>
                        <div className="mb-4">
                          <p>through balance sheets, charts and tools to control the business</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/fraud.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Anti-fraud systems</h2>
                        </a>
                        <div className="mb-4">
                          <p>to prevent hackers and money thefts</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/returns.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Support of returns</h2>
                        </a>
                        <div className="mb-4">
                          <p>at the same cost of sale and without delays or procedures</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/subscription.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Recurring payments and subscriptions</h2>
                        </a>
                        <div className="mb-4">
                          <p>create services and users in your own business model</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/university.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">Investment education</h2>
                        </a>
                        <div className="mb-4">
                          <p>for users who are not experts</p>
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-60 d-flex">
                    <article className="article article--blog-post w-100">
                      <a translate="true" href="#" className="article__thumbnail mx-auto d-block">
                        <img className="card-img-top mx-auto d-block" src="/img/benefits/mobile-payment.svg" alt="blog-image" />
                      </a>
                      <div className="card-body p-4 text-center">
                        <a className="mb-10" href="#">
                          <h2 className="h6-font btn-text-hover transition-default">New payment method</h2>
                        </a>
                        <div className="mb-4">
                          <p>for your business and opportunity for new customers</p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-color-primary space">
          <div className="background-holder background-holder--contain">
            <img src="/img/benefits/cta-bg.png" alt="pattern" className="background-image-holder" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 text-center mx-auto">
                <h3 className="mb-20">Try VCA for free today!</h3>
                <p className="lead mb-50">Millions of people around the world have already made
                Virtual capital of America the place where their dream happens.</p>
                <a href="https://app.virtualcapitalofamerica.com" className="btn btn--bg-white btn--color-primary btn-3d-hover btn-splash-hover">
                  <span className="btn__text">Get started</span>
                </a>
              </div>
            </div>
          </div>
        </section>

        <div className="height-emulator d-none d-lg-block"></div>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default BenefitsPage;

