const React = require('react');

class HeaderMain extends React.Component {
  render () {
    return (
      <nav className="navbar navbar-expand-lg navbar-light main-header d-flex justify-content-between">
        {/* Mobile content */}
        <section className="d-flex d-lg-none d-xl-none">
          <button className="navbar-toggler" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="/">
            <img className="navbar-brand__regular d-block my-auto" width="50" src="/img/logo-inverse.svg" alt="brand-logo" />
          </a>
        </section>

        <section className="d-flex flex-column flex-md-row justify-content-md-between navbar-inner w-md-100">
          {/* Left side */}
          <div className="d-flex">
            <a className="navbar-brand d-none d-md-flex" href="/">
              <img className="navbar-brand__regular d-block my-auto" width="50" src="/img/logo-inverse.svg" alt="brand-logo" />
            </a>
            <div className="mr-md-auto w-100">
              <nav>
                <ul className="navbar-nav" id="navbar-nav">
                  {/* <li className="nav-item">
                  <a className="nav-link" href="/benefits">Benefits</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/services">Products & services</a>
                </li> */}
                  <li className="nav-item">
                    <a className="nav-link" href="/business">Business</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/">Personal</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/network">Network</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          {/* Right side */}
          <div className="d-flex">
            <div className="ml-md-auto w-100">
              <ul className="navbar-nav">
                <li className="menu-item px-md-3 ml-md-auto dropdown">
                  <a translate="true" className="link nav-link dropdown-toggle" href="#" id="language-dropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">English</a>
                  <div className="dropdown-menu" aria-labelledby="language-dropdown">
                    <a href="/en/" className="dropdown-item item-light-hover" data-lang="us"
                      {...{ 'v-on:click': 'langItemOnClick($event)' }}>English</a>
                    <a href="/co/" className="dropdown-item item-light-hover" data-lang="co"
                      {...{ 'v-on:click': 'langItemOnClick($event)' }}>Español</a>
                    <a href="/it/" className="dropdown-item item-light-hover" data-lang="it"
                      {...{ 'v-on:click': 'langItemOnClick($event)' }}>Italiano</a>
                  </div>
                </li>
                <li className="nav-item d-flex justify-content-center my-4 my-md-0">
                  {/* Desktop button */}
                  <a href="https://app.virtualcapitalofamerica.com/signup/"
                    className="btn btn--bg-primary btn--sm btn-3d-hover btn-splash-hover get-started-main d-none d-lg-block my-auto">
                    <span className="btn__text">Online banking</span>
                  </a>
                  {/* Mobile Button */}
                  <a href="https://app.virtualcapitalofamerica.com"
                    className="btn btn-outline-primary btn--sm btn-3d-hover btn-splash-hover d-lg-none d-xl-none my-auto">
                    <span className="btn__text">Online banking</span>
                  </a>
                </li>
                <li className="nav-item d-flex justify-content-center">
                  <a href="https://app.virtualcapitalofamerica.com/login" className="btn btn--arrow-after btn--sm btn-border-hover my-auto">
                    <span className="btn__text">Login</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </nav>

    );
  }
}

export default HeaderMain;
