import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class ComingSoonPage extends React.Component {
  render () {

    return (
      <>

        <section className="mt-5 h-lg-100vh d-flex align-items-center justify-content-center">
          <div className="container d-flex">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-12 col-md-8 col-lg-6 mx-auto mb-50 mb-lg-0 pt-5 pt-lg-0 ">
                <img src="/img/comming-soon.png" alt="error-page" />
              </div>
              <div className="col-12 col-md-8 col-lg-5 mx-auto ml-lg-auto text-center text-lg-left">
                <div className="mb-30">
                  <h1 className="color-primary h3-font mb-20">We're coming soon. </h1>
                  <p>Our website is under construction. We'll be here soon with our new awesome site, subscribe to be notified.</p>
                </div>

                <form action="#" className="form form--sm position-relative">
                  <div className="input-group mb-3 mb-sm-0 z-ComingSoonPage1">
                    <input type="email" className="form-control" placeholder="Enter your email address" aria-label="Recipient's username" />
                  </div>
                  <button className="btn btn--submit btn--bg-primary pos-abs-top-right" type="submit">
                    <span className="btn__text">Notify me</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default ComingSoonPage;
