import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class PartnershipPage extends React.Component {
  render () {

    return (
      <>

        <section className="space bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <div className="section-title">
                  <h1 className="h3-font mb-3 text-center">Partnerships</h1>
                  <p>In Virtual Capital of America we look for to be your partner, 
                  if you have an enterprise or a small business every are the same level and everyone is
                  important for us. We are developing powerfull tools for your needs to improve your 
                  economy and provide an experience reloaded with your customers.</p>
                  <br />
                  <p>An example about these tools are our POS system to manage stocks, products, online
                    payments, providers and more or our Instagram bot to automate interactions with your
                    followers or to find new possible customers in the network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default PartnershipPage;
