const React = require('react');

class Cookies extends React.Component {
  render () {
    let bindings = {
      attr: {},
      events: {
        acceptCookieOnClick: { 'v-on:click': 'acceptCookieOnClick($event)' },
      },
      lists: {},
      style: {},
      visibility: {}
    }

    return (
      <div className="cookie-alert-box-wrapper d-none hide">
        <div className="cookie-alert-box-bg">
          <div className="cookie-alert-box-logo"> </div>
          <div className="cookie-alert-box-body">
            <p>We use cookies to personalize content and ads, to provide social media features and to analyze our
              traffic. Some of these cookies also help improve your user experience on our websites, assist with
              navigation and your ability to provide feedback, and assist with our promotional and marketing efforts.</p>
            <p>Please read our Cookie Policy for a more detailed description and click on the settings button to customize
            how the site uses cookies for you.</p>
          </div>
          <div className="cookie-clearfix"></div>
          <div className="cookie-alert-box-button-container d-flex">
            <div className="cookie-alert-box-button cookie-button-close">
              <div className="cookie-alert-box-button-middle"><a className="cookie-alert-box-close" href="#">Close</a></div>
            </div>
            <div className="cookie-alert-box-button cookie-button-allow">
              <div {...bindings.events.acceptCookieOnClick} className="btn cookie-alert-box-button-middle"><a className="cookie-allow-all" href="#">Accept Cookies</a></div>
            </div>
            <div className="cookie-alert-box-button cookie-button-more">
              <div className="cookie-alert-box-button-middle"><a className="cookie-toggle-display" href="#">Settings</a></div>
            </div>
          </div>
          <div className="cookie-clearfix cookie-alert-box-bottom-padding"></div>
        </div>
      </div>

    );
  }
}

export default Cookies;
