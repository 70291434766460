import React, { useEffect, useState } from 'react';

import Footer from '../../components/footer/_footerGeneral';
import Cookies from '../../components/cookies/_cookiesMain';

class ServicesPage extends React.Component {
  render () {

    return (
      <>
        <section className="hero hidden bg-color-grey">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-8 mx-auto text-center">
                <div className="cd-intro">
                  <span className="intro">Introducing Virtual capital of America</span>
                  <h1 className="hero__title h3-font cd-headline clip is-full-width">
                    <span>Our services for</span>
                    <span className="cd-words-wrapper color-primary">
                      <b className="is-visible">Banking</b>
                      <b className="is-hidden">Payments</b>
                      <b className="is-hidden">Investments</b>
                      <b className="is-hidden">Exchanges</b>
                    </span>
                  </h1>
                  <p className="lead hero__description">Enjoy exceptional business, opportunities and investments experiences with VCA.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Neobank */}
        <section className="space bg-color-white">
          <div className="container position-relative">
            <div className="background-holder background-holder--circle">
              <img src="/img/oval.svg" alt="oval" className="background-image-holder" />
            </div>
            <div className="row flex-row-reverse">
              <div className="col-12 col-lg-6 mb-40 mb-lg-0">
                <picture>
                  <img src="/img/services/neobank.png" alt="neobank" />
                </picture>
              </div>
              <div className="col-12 col-lg-5 ml-auto" data-sr-id="5">
                <h3 className="mb-30">Banking</h3>
                <p className="mb-30">At Virtual Capital of America we are using the most advanced
                and innovative cybersecurity technologies to protect your money. These
                techniques include advanced fraud detection systems and we implement a technology
                called Key Vault© to protect all your capital.</p>

                <a translate="true" href="#" className="btn btn--underline btn--color-primary btn-text-hover">
                  <span className="btn__text">Find out more</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End Neobank */}

        {/* Payments */}
        <section className="space bg-color-grey">
          <div className="container position-relative">
            <div className="row">
              <div className="col-12 col-lg-6 mb-40 mb-lg-0">
                <picture>
                  <img src="/img/services/payments.png" alt="payments" />
                </picture>
              </div>
              <div className="col-12 col-lg-5 ml-auto" data-sr-id="5">
                <h3 className="mb-30">Payments</h3>
                <p className="mb-30">Pay anywhere, anytime. Our headquarters is developing a
                strong network of merchants around the world to provide a solid payment
                method where you can buy anything. However, if the trade you are looking
                for is not our partner, use the prepaid debit card to pay at the point or
                withdraw the money from any ATM in the world.</p>

                <a translate="true" href="#" className="btn btn--underline btn--color-primary btn-text-hover">
                  <span className="btn__text">Find out more</span>
                </a>
              </div>
            </div>

          </div>

        </section>
        {/* End Payments */}

        {/* Investments */}
        <section className="space bg-color-white">
          <div className="container position-relative">
            <div className="background-holder background-holder--circle">
              <img src="/img/pricing-bg.svg" alt="oval" className="background-image-holder" />
            </div>
            <div className="row flex-row-reverse">
              <div className="col-12 col-lg-6 mb-40 mb-lg-0">
                <picture>
                  <img src="/img/services/investments.png" alt="investments" />
                </picture>
              </div>
              <div className="col-12 col-lg-5 ml-auto" data-sr-id="5">
                <h3 className="mb-30">Investments</h3>
                <p className="mb-30">Everyone can be an investment expert. If you want to increase
                your profits from surpluses, become a capital investor!, participate in collective
                portfolios, invest in cryptocurrencies or lend money with different interest
                rates and grow your capital. We offer alternatives to novice and advanced users,
                also a way to create investment robots.</p>

                <a translate="true" href="#" className="btn btn--underline btn--color-primary btn-text-hover">
                  <span className="btn__text">Find out more</span>
                </a>
              </div>

            </div>
          </div>
        </section>
        {/* End Investments */}

        {/* Exchanges */}
        <section className="space bg-color-grey">
          <div className="container position-relative">
            <div className="row">
              <div className="col-12 col-lg-6 mb-40 mb-lg-0">
                <picture>
                  <img src="/img/services/exchange.png" alt="exchange" />
                </picture>
              </div>
              <div className="col-12 col-lg-5 ml-auto" data-sr-id="5">
                <h3 className="mb-30">Exchange</h3>
                <p className="mb-30">In Virtual Capital of America we offer different ways
                to convert your money into another currency, send it to friends, request
                a loan between equals or simpler, sell your assets to another person and
                generate profits from foreign exchange operations.</p>

                <a translate="true" href="#" className="btn btn--underline btn--color-primary btn-text-hover">
                  <span className="btn__text">Find out more</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End Exchanges */}

        {/* Action call */}
        <section>
          <div className="container">
            <div className="row my-5">
              <div className="col-12 col-lg-6 mx-auto text-center">
                <h3 className="mb-20">Open your account today</h3>
                <p className="mb-40">When you are our client you get more.</p>
                <a href="https://app.virtualcapitalofamerica.com" className="btn btn--bg-primary btn-3d-hover btn-splash-hover"><span className="btn__text">Open account</span></a>
              </div>
            </div>
          </div>
        </section>
        {/* End action call */}

        <Footer locale={this.props?.locale} />

        <Cookies locale={this.props?.locale} />
      </>
    );
  }
}

export default ServicesPage;

